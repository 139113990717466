import React from 'react';
import { Func } from '@tinkoff/utils/typings/types';
import styles from './Popup.module.css';
import ArrowIcon from '../../icons/ArrowIcon';
import classnames from 'classnames/bind';
import Text from '../Text/Text';

const cx = classnames.bind(styles);

interface IPopupProps {
    popupTitle?: string;
    popupSubtitle?: string;
    popupLinkText?: string;
    popupLink?: string;
    isOpened: boolean;
    onCloseHandler: Func;
}

const Popup: React.FC<IPopupProps> = ({
    popupTitle,
    popupSubtitle,
    popupLinkText,
    popupLink,
    isOpened,
    onCloseHandler,
    children,
}) => {
    return (
        <div className={cx('popupWrap', { popupClose: !isOpened })}>
            <div className={styles.popupOverlay} onClick={onCloseHandler}></div>
            <div
                className={cx('popup', {
                    popupContentText: popupTitle,
                    popupContainer: !popupTitle,
                })}
            >
                {popupTitle && (
                    <Text variant="popupTitle" isBold={true}>
                        {popupTitle}
                    </Text>
                )}

                {popupSubtitle && (
                    <div className={styles.popupSubtitle}>
                        <Text variant="p2" isBold={true}>
                            {popupSubtitle}
                        </Text>
                    </div>
                )}

                <button onClick={onCloseHandler} className={styles.popupBtnClose} />
                {children && <div className={styles.popupContent}>{children}</div>}
                {popupLinkText && (
                    <a href={popupLink} className={styles.popupLinkWrap}>
                        <span className={cx('popupLink', 'popupText')}>
                            {popupLinkText}
                        </span>
                        <span className={styles.arrow}>
                            <ArrowIcon />
                        </span>
                    </a>
                )}
            </div>
        </div>
    );
};

export default Popup;
