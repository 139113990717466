import { combineReducers } from 'redux';
import {
    firebaseReducer,
    FirebaseReducer,
    FirestoreReducer,
    firestoreReducer,
} from 'react-redux-firebase';

interface IUserProfile {
    email: string;
}

export interface IExampleFirebaseC {
    text: string;
}

// create schema for the DB
interface IDBSchema {
    notes: IExampleFirebaseC;
    [name: string]: any;
}
interface IRootState {
    firebase: FirebaseReducer.Reducer<IUserProfile, IDBSchema>;
    firestore: FirestoreReducer.Reducer;
}

// @ts-ignore
export const rootReducer = combineReducers<IRootState>({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
