import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/database';
import React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { firebaseConfig, reduxFirebase } from './firebaseConfig';
import configureStore from './redux/store';
import { HomeContainer } from './components/HomeContainer/HomeContainer';
import { createFirestoreInstance } from 'redux-firestore';
const initialState = {};
const store = configureStore(initialState);

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.functions();

export const App: React.FC = () => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider
            firebase={firebase}
            config={reduxFirebase}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}
        >
            <HomeContainer />
        </ReactReduxFirebaseProvider>
    </Provider>
);
