export const firebaseConfig = {
    apiKey: 'AIzaSyBxiiH9NSNhJ_wfklpCKFBwkiBqsZg23ys',
    authDomain: 'wh-hero.firebaseapp.com',
    databaseURL: 'https://wh-hero.firebaseio.com',
    projectId: 'wh-hero',
    storageBucket: 'wh-hero.appspot.com',
    messagingSenderId: '829983521179',
    appId: '1:829983521179:web:9c9b47a8c1b32737bd493e',
};
export const reduxFirebase = {
    userProfile: 'users',
    useFirestoreForProfile: true,
    enableLogging: false,
};
