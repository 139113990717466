import React from 'react';
import styles from './HeaderGif.module.css';
import Star from '../Star/Star';

interface IHeaderGifMoneyProps {
    imgBackUrl: string;
}

const HeaderGifMoney: React.FC<IHeaderGifMoneyProps> = ({ imgBackUrl }) => {
    return (
        <div className={styles.gifWrap}>
            <div className={styles.gif}>
                <Star
                    starsStep={'step4'}
                    imgUrl={'/img/gif/200-2.gif'}
                    isHeaderStar={'true'}
                />
            </div>
            <div
                className={styles.backMoney}
                style={{
                    backgroundImage: `url(${imgBackUrl})`,
                }}
            />
        </div>
    );
};

export default HeaderGifMoney;
