import React, { lazy, Suspense } from 'react';
import styles from './HomePage.module.scss';
import Header from '../Header/Header';
import BlockStudent from '../BlockStudent/BlockStudent';
const Footer = lazy(() => import('../Footer/Footer'));
const BlockForYou = lazy(() => import('../BlockForYou/BlockForYou'));
const Modules = lazy(() => import('../Modules/Modules'));
const TiltCards = lazy(() => import('../TiltCards/TiltCards'));
const Bounties = lazy(() => import('../Bounties/Bounties'));
const BannerLesson = lazy(() => import('../BannerLesson/BannerLesson'));
const CardGaranti = lazy(() => import('../CardGaranti/CardGaranti'));
const CardProfessions = lazy(() => import('../CardProfessions/CardProfessions'));
const CardFormat = lazy(() => import('../CardFormat/CardFormat'));
const CardComposition = lazy(() => import('../CardComposition/CardComposition'));
const BlockTeam = lazy(() => import('../BlockTeam/BlockTeam'));
const ReviewSlider = lazy(() => import('../ReviewSlider/ReviewSlider'));
export const HomePage: React.FC = data => {
    return (
        <div className={styles.MainContainer}>
            <Header />
            <div className={styles.SectionContainer}>
                <BlockStudent />
            </div>
            <Suspense fallback={''}>
                <div className={styles.SectionContainer}>
                    {/*@ts-ignore*/}
                    <CardProfessions blockData={data.data.cards_Profession} />
                    {/*@ts-ignore*/}
                    <Modules blockData={data.data.modules} />
                </div>
                {/*@ts-ignore*/}
                <BannerLesson blockData={data.data.bannerLesson} />
                <div className={styles.SectionContainer}>
                    <ReviewSlider />
                    <BlockForYou />
                    {/*@ts-ignore*/}
                    <CardGaranti blockData={data.data.cards_Garant} />
                    {/*@ts-ignore*/}
                    <TiltCards blockData={data.data.blockTiltCards} />
                    <CardFormat />
                    {/*@ts-ignore*/}
                    <CardComposition blockData={data.data.blockComposition} />
                    {/*@ts-ignore*/}
                    <Bounties blockData={data.data.blockBounties} />
                    {/*@ts-ignore*/}
                    <BlockTeam blockData={data.data.blockTeam} />
                    <Footer />
                </div>
            </Suspense>
        </div>
    );
};
