import React from 'react';
import classNames from 'classnames/bind';
import styles from '../components/Link/Link.module.css';

const cx = classNames.bind(styles);

interface IArrowIconSmallProps {
    isArrowIconSmallWhite?: boolean;
}

const ArrowIconSmall: React.FC<IArrowIconSmallProps> = ({ isArrowIconSmallWhite }) => (
    <svg
        className={cx('iconSmall', {
            white: isArrowIconSmallWhite,
        })}
        width="44"
        height="10"
        viewBox="0 0 19 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.14565 4.4288C0.994095 4.4288 0.84875 4.489 0.741586 4.59616C0.634423 4.70333 0.574219 4.84867 0.574219 5.00023C0.574219 5.15178 0.634423 5.29712 0.741586 5.40429C0.84875 5.51145 0.994095 5.57165 1.14565 5.57165V4.4288ZM17.5502 5.4048C17.6034 5.35172 17.6457 5.28866 17.6745 5.21923C17.7033 5.14981 17.7181 5.07539 17.7181 5.00023C17.7181 4.92506 17.7033 4.85064 17.6745 4.78122C17.6457 4.71179 17.6034 4.64873 17.5502 4.59565L13.9136 0.959083C13.8605 0.905954 13.7974 0.86381 13.728 0.835056C13.6586 0.806303 13.5842 0.791504 13.5091 0.791504C13.4339 0.791504 13.3595 0.806303 13.2901 0.835056C13.2207 0.86381 13.1576 0.905954 13.1045 0.959083C13.0514 1.01221 13.0092 1.07529 12.9805 1.1447C12.9517 1.21412 12.9369 1.28852 12.9369 1.36365C12.9369 1.43879 12.9517 1.51319 12.9805 1.58261C13.0092 1.65202 13.0514 1.7151 13.1045 1.76823L16.3376 5.00023L13.1045 8.23223C13.0514 8.28535 13.0092 8.34843 12.9805 8.41784C12.9517 8.48726 12.9369 8.56166 12.9369 8.6368C12.9369 8.71193 12.9517 8.78633 12.9805 8.85575C13.0092 8.92517 13.0514 8.98824 13.1045 9.04137C13.1576 9.0945 13.2207 9.13664 13.2901 9.16539C13.3595 9.19415 13.4339 9.20895 13.5091 9.20895C13.5842 9.20895 13.6586 9.19415 13.728 9.16539C13.7974 9.13664 13.8605 9.0945 13.9136 9.04137L17.5502 5.4048ZM1.14565 5.57165H16.1704V4.4288H1.14565V5.57165Z"
            fill="#333333"
        />
        <path
            d="M1.14565 4.4288C0.994095 4.4288 0.84875 4.489 0.741586 4.59616C0.634423 4.70333 0.574219 4.84867 0.574219 5.00023C0.574219 5.15178 0.634423 5.29712 0.741586 5.40429C0.84875 5.51145 0.994095 5.57165 1.14565 5.57165M1.14565 4.4288V5.57165M1.14565 4.4288H16.1704V5.57165H1.14565M17.5502 5.4048C17.6034 5.35172 17.6457 5.28866 17.6745 5.21923C17.7033 5.14981 17.7181 5.07539 17.7181 5.00023C17.7181 4.92506 17.7033 4.85064 17.6745 4.78122C17.6457 4.71179 17.6034 4.64873 17.5502 4.59565L13.9136 0.959083C13.8605 0.905954 13.7974 0.86381 13.728 0.835056C13.6586 0.806303 13.5842 0.791504 13.5091 0.791504C13.4339 0.791504 13.3595 0.806303 13.2901 0.835056C13.2207 0.86381 13.1576 0.905954 13.1045 0.959083C13.0514 1.01221 13.0092 1.07529 12.9805 1.1447C12.9517 1.21412 12.9369 1.28852 12.9369 1.36365C12.9369 1.43879 12.9517 1.51319 12.9805 1.58261C13.0092 1.65202 13.0514 1.7151 13.1045 1.76823L16.3376 5.00023L13.1045 8.23223C13.0514 8.28535 13.0092 8.34843 12.9805 8.41784C12.9517 8.48726 12.9369 8.56166 12.9369 8.6368C12.9369 8.71193 12.9517 8.78633 12.9805 8.85575C13.0092 8.92517 13.0514 8.98824 13.1045 9.04137C13.1576 9.0945 13.2207 9.13664 13.2901 9.16539C13.3595 9.19415 13.4339 9.20895 13.5091 9.20895C13.5842 9.20895 13.6586 9.19415 13.728 9.16539C13.7974 9.13664 13.8605 9.0945 13.9136 9.04137L17.5502 5.4048Z"
            stroke="#333333"
        />
    </svg>
);

export default ArrowIconSmall;
