import React from 'react';
import { isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { HomePage } from '../HomePage/HomePage';
import Loader from '../Loader/Loader';

// @ts-ignore
export const HomeContainer: React.FC = () => {
    useFirebaseConnect([{ path: '/DB' }]);
    const data = useSelector((state: AppState) => {
        return state.firebase.data.DB;
    });

    if (!isLoaded(data)) {
        return <Loader />;
    }

    if (isEmpty(data)) {
        return <div>No Notes Found</div>;
    }

    if (isLoaded(data)) {
        // @ts-ignore
        return <HomePage data={data} />;
    }
};
