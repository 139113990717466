import React, { useState } from 'react';
import styles from './Header.module.css';
import WebHeroLogo from '../WebHeroLogo/WebHeroLogo';
import Text from '../Text/Text';
import SignInButton from '../SignInButton/SignInButton';
import Link from '../Link/Link';
import HeaderGifMoney from '../HeaderGif/HeaderGifMoney';
import { createMarkup } from '../../utils/createMarkup';

const { innerWidth } = window;

const LK = 'Личный кабинет';
const LINK = 'Начать бесплатно';

const TEXT_INFO: { [key: string]: string } = {
    TEXT1: 'Обучаем веб&#8209;разработке&#8194;',
    TEXT2: 'за 3 месяца с нуля',
    TEXT3: 'и гарантируем трудоустройство ',
    TEXT4: 'с первой зарплатой от 30&nbsp;000&nbsp;₽',
    TEXT1_SMALL: 'Обучаем ',
    TEXT2_SMALL: 'веб-разработке ',
    TEXT3_SMALL: 'за 3 месяца с нуля',
    TEXT4_SMALL: 'и гарантируем ',
    TEXT5_SMALL: 'трудоустройство ',
    TEXT6_SMALL: 'с первой  ',
    TEXT7_SMALL: 'зарплатой ',
    TEXT8_SMALL: 'от 30&nbsp;000&nbsp;₽',
};
const DESCRIPTION =
    'Онлайн-школа востребованных IT-профессий с последующим трудоустройством';

const Header: React.FC = () => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
        if (TEXT_INFO.TEXT4) return setHovered(true);
    };
    const handleHoverLeave = () => {
        return setHovered(false);
    };

    return (
        <div className={styles.header}>
            <div className={styles.header_wrap}>
                <div className={styles.logo_lk}>
                    <WebHeroLogo
                        type={innerWidth > 650 ? 'bigWhiteYellow' : 'topHeaderSmall'}
                    />
                    <div className={styles.buttonWrap}>
                        <SignInButton
                            isIconColorWhite
                            isSmallButton={innerWidth <= 768}
                            isBigButton
                            link={'https://lk.webheroschool.ru/cms/system/login'}
                        >
                            <span className={styles.btn_text}>{LK}</span>
                        </SignInButton>
                    </div>
                </div>
                <div className={styles.info}>
                    {innerWidth > 480 ? (
                        <div className={styles.text_wrap}>
                            <Text variant="topHeader" isTextColorWhite>
                                <div className={styles.titleWrap}>
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT1,
                                        )}
                                    />
                                    <span className={styles.yellow}>
                                        <div
                                            dangerouslySetInnerHTML={createMarkup(
                                                TEXT_INFO.TEXT2,
                                            )}
                                        />
                                    </span>
                                </div>

                                <div className={styles.titleWrap}>
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT3,
                                        )}
                                    />
                                </div>
                                <span
                                    onMouseLeave={handleHoverLeave}
                                    onMouseEnter={handleHover}
                                    className={styles.yellow}
                                >
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT4,
                                        )}
                                    />
                                </span>
                            </Text>
                        </div>
                    ) : (
                        <div className={styles.text_wrap}>
                            <Text variant="topHeader" isTextColorWhite>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        TEXT_INFO.TEXT1_SMALL,
                                    )}
                                />
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        TEXT_INFO.TEXT2_SMALL,
                                    )}
                                />
                                <span className={styles.yellow}>
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT3_SMALL,
                                        )}
                                    />{' '}
                                </span>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        TEXT_INFO.TEXT4_SMALL,
                                    )}
                                />
                                <div
                                    dangerouslySetInnerHTML={createMarkup(
                                        TEXT_INFO.TEXT5_SMALL,
                                    )}
                                />
                                <span className={styles.yellow}>
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT6_SMALL,
                                        )}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT7_SMALL,
                                        )}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            TEXT_INFO.TEXT8_SMALL,
                                        )}
                                    />
                                </span>
                            </Text>
                        </div>
                    )}

                    <div className={styles.link}>
                        <Link
                            textVariant="link"
                            isWhite
                            isArrowIconBigWhite
                            isArrowBig
                            link={'https://lk.webheroschool.ru/reg_webmarafon_site'}
                        >
                            {LINK}
                        </Link>
                    </div>
                    <div className={styles.descriptionWrap}>
                        <Text variant="p2Header" isTextColorWhite>
                            {DESCRIPTION}
                        </Text>
                    </div>
                </div>
                <div className={styles.gif}>
                    {hovered ? (
                        <HeaderGifMoney imgBackUrl={'/img/headerGifStar/yellow.svg'} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default Header;
