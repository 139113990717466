import { compose, createStore } from 'redux';
import { rootReducer } from './reducers';

export default function configureStore(initialState: {}): any {
    const createStoreWithMiddleware = compose(
        typeof window === 'object' &&
            typeof (window as any).devToolsExtension !== 'undefined'
            ? (): any => (window as any).__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line no-underscore-dangle
            : (f: any): any => f,
    )(createStore);

    return createStoreWithMiddleware(rootReducer);
}
