import React from 'react';
import styles from '../WebHeroLogo/WebHeroLogo.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface IWebHeroLogoProps {
    type:
        | 'bigWhiteYellow'
        | 'bigBlackYellow'
        | 'bigWhiteBlack'
        | 'circlePurple'
        | 'topHeaderSmall'
        | 'squarePurple';
}

const WebHeroLogo: React.FC<IWebHeroLogoProps> = ({ type }) => {
    return (
        <div
            className={cx('common', {
                [type]: true,
            })}
        ></div>
    );
};

export default WebHeroLogo;
