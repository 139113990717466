import React from 'react';
import styles from './Star.module.css';

interface IStarProps {
    imgUrl?: string;
    starsStep: 'step1' | 'step2' | 'step3' | 'step4' | 'step5';
    isHeaderStar?: string;
}

const steps = {
    step1:
        'M80 0L94.1675 22.5202L117.178 9.16352L119.257 35.6882L145.839 34.5548L135.353 59.0074L159.417 70.3571L138.768 87.1358L154.801 108.368L128.721 113.629L133.05 139.881L107.512 132.419L99.1452 157.675L80 139.2L60.8547 157.675L52.4884 132.419L26.9502 139.881L31.2794 113.629L5.1987 108.368L21.2316 87.1358L0.58329 70.3571L24.647 59.0074L14.1613 34.5548L40.7431 35.6882L42.8221 9.16352L65.8325 22.5202L80 0Z',
    step2:
        'M80 0L86.5868 13.1236L95.6072 1.53718L99.5071 15.6936L110.615 6.08964L111.678 20.7349L124.446 13.4824L122.631 28.0537L136.569 23.4315L131.946 37.3688L146.518 35.5544L139.265 48.3221L153.91 49.3853L144.306 60.4929L158.463 64.3928L146.876 73.4132L160 80L146.876 86.5868L158.463 95.6072L144.306 99.5071L153.91 110.615L139.265 111.678L146.518 124.446L131.946 122.631L136.569 136.569L122.631 131.946L124.446 146.518L111.678 139.265L110.615 153.91L99.5071 144.306L95.6072 158.463L86.5868 146.876L80 160L73.4132 146.876L64.3928 158.463L60.4929 144.306L49.3853 153.91L48.3221 139.265L35.5544 146.518L37.3688 131.946L23.4315 136.569L28.0537 122.631L13.4824 124.446L20.7349 111.678L6.08964 110.615L15.6936 99.5071L1.53718 95.6072L13.1236 86.5868L0 80L13.1236 73.4132L1.53718 64.3928L15.6936 60.4929L6.08964 49.3853L20.7349 48.3221L13.4824 35.5544L28.0537 37.3688L23.4315 23.4315L37.3688 28.0537L35.5544 13.4824L48.3221 20.7349L49.3853 6.08964L60.4929 15.6936L64.3928 1.53718L73.4132 13.1236L80 0Z',
    step3:
        'M80 0L86.4171 29.2037L99.8952 2.51334L98.848 32.3954L118.54 9.89546L110.095 38.5783L134.764 21.6825L119.45 47.3639L147.546 37.1339L126.327 58.2001L156.085 55.2786L130.293 70.4061L159.842 74.9768L131.099 83.2149L158.583 94.9905L128.694 95.8217L152.386 114.062L123.23 107.434L141.641 130.994L115.049 117.323L127.023 144.721L104.666 124.867L109.45 154.382L92.7329 129.591L90.0267 159.369L80 131.2L69.9733 159.369L67.2671 129.591L50.55 154.382L55.3342 124.867L32.9772 144.721L44.9512 117.323L18.3589 130.994L36.7704 107.434L7.61384 114.062L31.3059 95.8217L1.41702 94.9905L28.901 83.2149L0.15786 74.9768L29.7069 70.4061L3.91548 55.2786L33.6729 58.2001L12.4538 37.1339L40.5497 47.3639L25.2362 21.6825L49.9054 38.5783L41.4597 9.89546L61.152 32.3954L60.1048 2.51334L73.5829 29.2037L80 0Z',
    step4:
        'M80 0L85.0185 29.0465L95.6072 1.53718L94.8626 31.0047L110.615 6.08964L104.136 34.8456L124.446 13.4824L112.481 40.4219L136.569 23.4315L119.578 47.5191L146.518 35.5544L125.154 55.8645L153.91 49.3853L128.995 65.1374L158.463 64.3928L130.953 74.9815L160 80L130.953 85.0185L158.463 95.6072L128.995 94.8626L153.91 110.615L125.154 104.136L146.518 124.446L119.578 112.481L136.569 136.569L112.481 119.578L124.446 146.518L104.136 125.154L110.615 153.91L94.8626 128.995L95.6072 158.463L85.0185 130.953L80 160L74.9815 130.953L64.3928 158.463L65.1374 128.995L49.3853 153.91L55.8645 125.154L35.5544 146.518L47.5191 119.578L23.4315 136.569L40.4219 112.481L13.4824 124.446L34.8456 104.136L6.08964 110.615L31.0047 94.8626L1.53718 95.6072L29.0465 85.0185L0 80L29.0465 74.9815L1.53718 64.3928L31.0047 65.1374L6.08964 49.3853L34.8456 55.8645L13.4824 35.5544L40.4219 47.5191L23.4315 23.4315L47.5191 40.4219L35.5544 13.4824L55.8645 34.8456L49.3853 6.08964L65.1374 31.0047L64.3928 1.53718L74.9815 29.0465L80 0Z',
    step5:
        'M80 0L83.2283 36.9208L91.9234 0.893532L89.6129 37.8831L103.58 3.55418L95.7827 39.7863L114.711 7.92249L101.6 42.5877L125.066 13.9009L106.935 46.2249L134.414 21.3559L111.668 50.6165L142.547 30.1208L115.694 55.6646L149.282 40L118.922 61.2562L154.47 50.7727L121.281 67.2666L157.994 62.1983L122.717 73.5614L159.776 74.0216L123.2 80L159.776 85.9784L122.717 86.4386L157.994 97.8017L121.281 92.7334L154.47 109.227L118.922 98.7438L149.282 120L115.694 104.335L142.547 129.879L111.668 109.383L134.414 138.644L106.935 113.775L125.066 146.099L101.6 117.412L114.711 152.078L95.7827 120.214L103.58 156.446L89.6129 122.117L91.9234 159.106L83.2283 123.079L80 160L76.7717 123.079L68.0766 159.106L70.3871 122.117L56.4196 156.446L64.2173 120.214L45.2893 152.078L58.4 117.412L34.9344 146.099L53.0652 113.775L25.5862 138.644L48.3322 109.383L17.4535 129.879L44.3065 104.335L10.718 120L41.0781 98.7438L5.5301 109.227L38.7193 92.7334L2.00577 97.8017L37.2825 86.4386L0.223694 85.9784L36.8 80L0.223694 74.0216L37.2825 73.5614L2.00577 62.1983L38.7193 67.2666L5.5301 50.7727L41.0781 61.2562L10.718 40L44.3065 55.6646L17.4535 30.1208L48.3322 50.6165L25.5862 21.3559L53.0652 46.2249L34.9344 13.9009L58.4 42.5877L45.2893 7.92249L64.2173 39.7863L56.4196 3.55418L70.3871 37.8831L68.0766 0.893532L76.7717 36.9208L80 0Z',
};

const Star: React.FC<IStarProps> = ({ imgUrl, starsStep }) => {
    return (
        <div className={styles.wrap}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 160 160"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <pattern id="pattern" width="100%" height="100%">
                    <image href={imgUrl} width="100%" className={styles.image} />
                </pattern>
                <path d={steps[starsStep]} fill="url(#pattern)" />
            </svg>
        </div>
    );
};

export default Star;
