import React, { useState } from 'react';
import styles from './Feedback.module.css';
import Popup from '../Popup';
import { Func } from '@tinkoff/utils/typings/types';
import ArrowIcon from '../../../icons/ArrowIcon';
import className from 'classnames/bind';

const cx = className.bind(styles);

interface IFeedbackProps {
    feedbackTitle?: string;
    feedbackSubtitle?: string;
    isOpened: boolean;
    onCloseHandler: Func;
    onHandleSendForm: Func;
    feedbackBtnText: string;
}

const Feedback: React.FC<IFeedbackProps> = ({
    feedbackTitle,
    feedbackSubtitle,
    isOpened,
    onCloseHandler,
    onHandleSendForm,
    feedbackBtnText,
}) => {
    const [inputName, setInputName] = useState('');
    const [inputNameStyleBorder, setInputNameBorder] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [inputEmailStyleBorder, setInputEmailBorder] = useState(false);

    const validateInputData = () => {
        setInputNameBorder(!inputName);
        setInputEmailBorder(!inputEmail);

        if (inputName !== '' && inputEmail !== '') {
            onHandleSendForm();
        }
    };

    const handleInputChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputName(event.target.value);
    };

    const handleInputChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputEmail(event.target.value);
    };

    return (
        <Popup
            popupSubtitle={feedbackSubtitle}
            popupTitle={feedbackTitle}
            isOpened={isOpened}
            onCloseHandler={onCloseHandler}
        >
            <div className={styles.feedbackInputWrap}>
                <input
                    value={inputName}
                    onChange={handleInputChangeName}
                    type="text"
                    className={cx({
                        feedbackInputText: true,
                        inputError: inputNameStyleBorder,
                    })}
                    required
                />
                <span className={styles.feedbackPlaceholder}>Твое имя</span>
            </div>

            <div className={styles.feedbackInputWrap}>
                <input
                    value={inputEmail}
                    onChange={handleInputChangeEmail}
                    type="text"
                    className={cx({
                        feedbackInputText: true,
                        inputError: inputEmailStyleBorder,
                    })}
                    required
                />
                <span className={styles.feedbackPlaceholder}>Твой E-mail</span>
            </div>
            <button onClick={validateInputData} className={styles.popupBtnWrap}>
                <span className={cx('popupBtn', 'popupBtnText')}>{feedbackBtnText}</span>
                <span className={styles.arrow}>
                    <ArrowIcon />
                </span>
            </button>
        </Popup>
    );
};

export default Feedback;
