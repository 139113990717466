import React, { useState } from 'react';
import styles from './BlockStudent.module.css';
import Text from '../Text/Text';
import classnames from 'classnames/bind';
import TextWithBackground from '../Text/textWithBackground/TextWithBackground';
import ArrowIconBig from '../../icons/ArrowIconBig';
import Feedback from '../Popup/Feedback/Feedback';
import ArrowIconSmall from '../../icons/ArrowIcon';

const { innerWidth } = window;
const cx = classnames.bind(styles);

interface IBlockStudentProps {
    link?: string;
}

const BlockStudent: React.FC<IBlockStudentProps> = ({ link }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const handleClick = () => {
        setIsOpenModal(!isOpenModal);
    };

    return (
        <div className={styles.BlockStudent}>
            <div className={styles.titleWrap}>
                <TextWithBackground
                    variant="h2"
                    isBold={true}
                    textsArray={[
                        { text: 'Наши ученики ' },
                        { text: 'трудоустраиваются', withBackground: true },
                    ]}
                />
                <TextWithBackground
                    variant="h2"
                    isBold={true}
                    textsArray={[{ text: 'в IT-компании от России до Англии' }]}
                />
            </div>
            <div className={styles.simpleText}>
                <Text variant="p2" isBold={true}>
                    Школа выпускает на рынок программистов по самым востребованным
                    направлениям и ведет до трудоустройства с гарантией
                </Text>
            </div>

            <div className={styles.content}>
                <div className={styles.firstRow}>
                    <div
                        className={cx('tinkoff', 'blockContentFirst', 'scrollMargin')}
                    ></div>
                    <div
                        className={cx('purrweb', 'blockContentFirst', 'scrollMargin')}
                    ></div>
                    <div className={cx('mts', 'blockContentFirst', 'scrollMargin')}></div>
                    <div
                        className={cx('sberbank', 'blockContentFirst', 'scrollMargin')}
                    ></div>
                    <div
                        className={cx('myWebS', 'blockContentFirst', 'scrollMargin')}
                    ></div>
                </div>
                <div className={styles.secondRow}>
                    <div
                        className={cx('epam', 'blockContentSecond', 'scrollMargin')}
                    ></div>
                    <div
                        className={cx('codezavod', 'blockContentSecond', 'scrollMargin')}
                    ></div>
                    <div
                        className={cx('t-g', 'blockContentSecond', 'scrollMargin')}
                    ></div>
                    <div
                        className={cx('sibdev', 'blockContentSecond', 'scrollMargin')}
                    ></div>
                    <div className={cx('g', 'blockContentSecond', 'scrollMargin')}></div>
                    <div
                        className={cx('contur', 'blockContentSecond', 'scrollMargin')}
                    ></div>
                </div>
            </div>
            <div className={styles.common} onClick={handleClick}>
                <button className={styles.btnLink}>
                    <Text variant="link" isBold={true}>
                        Подробнее о гарантиях
                    </Text>
                </button>
                {innerWidth > 768 ? (
                    <div className={styles.icon}>
                        <ArrowIconBig />
                    </div>
                ) : (
                    <div className={styles.iconSmall}>
                        <ArrowIconSmall />
                    </div>
                )}
            </div>
            <Feedback
                feedbackTitle="Расскажи о себе"
                feedbackSubtitle="И мы отправим тебе программу обучения"
                isOpened={isOpenModal}
                onCloseHandler={() => setIsOpenModal(false)}
                onHandleSendForm={() => {}}
                feedbackBtnText="Подробнее об обучении"
            />
        </div>
    );
};

export default BlockStudent;
