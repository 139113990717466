import React from 'react';
import styles from './SignInButton.module.css';
import SignInButtonIcon from '../../icons/SignInButtonIcon';
import classNames from 'classnames/bind';
import Text from '../Text/Text';

const cx = classNames.bind(styles);

interface ISignInButtonProps {
    isYellowButton?: boolean;
    isIconColorWhite?: boolean;
    isSmallButton?: boolean;
    isBigButton?: boolean;
    isButtonTitle?: boolean;
    isFooterButton?: boolean;
    link?: string;
}

const SignInButton: React.FC<ISignInButtonProps> = ({
    isYellowButton,
    isIconColorWhite,
    isSmallButton,
    isBigButton,
    isButtonTitle,
    isFooterButton,
    children,
    link,
}) => {
    return (
        <form action={link} target="_blank" className={styles.form}>
            <button
                className={cx('button', {
                    'yellow-button': isYellowButton,
                    'small-button': isSmallButton,
                    'footer-button': isFooterButton,
                    button: isBigButton,
                })}
            >
                <SignInButtonIcon isIconColorWhite={isIconColorWhite} />
                <div
                    className={cx('buttonTitle', {
                        showTitle: isButtonTitle,
                    })}
                >
                    <Text variant="p2button">{children}</Text>
                </div>
            </button>
        </form>
    );
};

export default SignInButton;
