import React from 'react';
import styles from './TextWithBackground.module.css';
import Text, { ITextProps } from '../Text';

export interface ITextWithBackgroundProps {
    variant: ITextProps['variant'];
    textsArray: {
        text: string;
        withBackground?: boolean;
        withSmallBackground?: boolean;
    }[];
    isBold?: boolean;
    isMedium?: boolean;
}

const TextWithBackground: React.FC<ITextWithBackgroundProps> = ({
    variant,
    isBold,
    isMedium,
    textsArray,
}) => {
    return (
        <Text variant={variant} isBold={isBold} isMedium={isMedium}>
            {textsArray.map((item, index) => {
                if (item.withBackground) {
                    return (
                        <span className={styles.wrapText}>
                            <span className={styles.back} />
                            <span className={styles.textWithBack}>
                                <pre className={styles.preformattedText}>{item.text}</pre>
                            </span>
                        </span>
                    );
                }

                if (item.withSmallBackground) {
                    return (
                        <span className={styles.wrapText}>
                            <span className={styles.smallBack} />
                            <span className={styles.textWithBack}>
                                <pre className={styles.preformattedText}>{item.text}</pre>
                            </span>
                        </span>
                    );
                }

                return item.text;
            })}
        </Text>
    );
};

export default TextWithBackground;
