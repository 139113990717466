import React from 'react';
import styles from './Loader.module.scss';
interface ILoaderProps {
    icon?: string;
}

const Loader: React.FC<ILoaderProps> = icon => {
    return (
        <div className={styles.LoaderIcon}>
            <svg
                width="208"
                height="208"
                viewBox="0 0 208 208"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.LoaderSVG}
            >
                <g className={styles.eb9ia5xhzdxn2_tr} id="eb9ia5xhzdxn2_tr">
                    <path
                        className={styles.eb9ia5xhzdxn2}
                        id="eb9ia5xhzdxn2"
                        d="M104,7C91.261800,7,78.648300,9.508980,66.879700,14.383700C55.111100,19.258400,44.417900,26.403400,35.410600,35.410600C26.403400,44.417900,19.258400,55.111100,14.383700,66.879700C9.508980,78.648300,7,91.261800,7,104"
                        transform="translate(-104,-104)"
                        fill="none"
                        stroke="rgb(255,210,46)"
                        stroke-width="12"
                        stroke-linecap="round"
                    />
                </g>

                <path
                    className={styles.eb9ia5xhzdxn4}
                    id="eb9ia5xhzdxn4"
                    d="M57.1685 130.33H67.5336L77.0883 97.4415H77.4813L87.0604 130.33H97.4256L111.819 80.027H100.201L91.8746 115.052H91.4325L82.2709 80.027H72.3232L63.137 114.979H62.7195L54.393 80.027H42.7751L57.1685 130.33ZM117.511 130.33H128.147V109.551H149.737V130.33H160.347V80.027H149.737V100.782H128.147V80.027H117.511V130.33Z"
                    fill="#FFD22E"
                />
            </svg>
        </div>
    );
};

export default Loader;
