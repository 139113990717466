import React from 'react';
import styles from './Link.module.css';
import classNames from 'classnames/bind';
import ArrowIconBig from '../../icons/ArrowIconBig';
import ArrowIconSmall from '../../icons/ArrowIcon';
import Text, { ITextProps } from '../Text/Text';
import { Func } from '@tinkoff/utils/typings/types';

const cx = classNames.bind(styles);

interface ILinkProps {
    isWhite?: boolean;
    isArrowIconBigWhite?: boolean;
    isArrowIconSmallWhite?: boolean;
    isBold?: boolean;
    textVariant: ITextProps['variant'];
    isArrowBig?: boolean;
    isArrowSmall?: boolean;
    link?: string;
    onClick?: Func;
}

const Link: React.FC<ILinkProps> = ({
    isWhite,
    children,
    isArrowIconBigWhite,
    isArrowIconSmallWhite,
    isBold,
    textVariant,
    isArrowBig,
    isArrowSmall,
    link,
    onClick,
}) => {
    return (
        <div className={styles.common} onClick={onClick}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                href={link}
                className={cx('link', {
                    white: isWhite,
                })}
                target={'_blank'}
                rel="noopener noreferrer"
            >
                <Text variant={textVariant} isBold={isBold}>
                    {children}
                </Text>
            </a>
            {isArrowBig ? (
                <ArrowIconBig isArrowIconBigWhite={isArrowIconBigWhite} />
            ) : null}
            {isArrowSmall ? (
                <ArrowIconSmall isArrowIconSmallWhite={isArrowIconSmallWhite} />
            ) : null}
        </div>
    );
};

export default Link;
