import React from 'react';
import styles from './Text.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface ITextProps {
    variant:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'p1'
        | 'p2'
        | 'p3'
        | 'link'
        | 'p2button'
        | 'p2Header'
        | 'popupTitle'
        | 'header'
        | 'topHeader'
        | 'moduleCard'
        | 'reviewTitle'
        | 'reviewHeader'
        | 'sliderDecorText'
        | 'bannerLessonTitle'
        | 'bannerLessonSubtitle'
        | 'tiltCardHeader'
        | 'textCardParagraph'
        | 'blockCard'
        | 'textCardHeader';
    isBold?: boolean;
    isMedium?: boolean;
    isTextColorWhite?: boolean;
}

const tags = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p1: 'p',
    p2: 'p',
    p2button: 'p',
    p2Header: 'p2Header',
    popupTitle: 'popupTitle',
    p3: 'p',
    link: 'p',
    header: 'h2',
    topHeader: 'h2',
    reviewTitle: 'h3',
    reviewHeader: 'h2',
    sliderDecorText: 'h4',
    moduleCard: 'moduleCard',
    bannerLessonTitle: 'bannerLessonTitle',
    bannerLessonSubtitle: 'bannerLessonSubtitle',
    tiltCardHeader: 'h2',
    BannerLessonTitle: 'BannerLessonTitle',
    BannerLessonSubtitle: 'BannerLessonSubtitle',
    textCardHeader: 'textCardHeader',
    textCardParagraph: 'textCardParagraph',
    blockCard: 'blockCard',
};

const Text: React.FC<ITextProps> = ({
    children,
    variant,
    isBold,
    isMedium,
    isTextColorWhite,
}) => {
    const Tag = tags[variant] as React.ElementType;

    return (
        <Tag
            className={cx('common-style', {
                [variant]: true,
                bold: isBold,
                medium: isMedium,
                white: isTextColorWhite,
            })}
        >
            {children}
        </Tag>
    );
};

export default Text;
